import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import Menu from '../../components/menuComponent';
import Article from '../../components/articleComponent';

import Articles from '../../data/articles';

const ArticleContainer = ({ location }) => {
  const { pathname } = location;
  const filter = pathname.split('/').filter((pathString) => pathString.length > 0).pop();
  const article = Articles.find((data) => data.link === filter);

  return (
    <Layout
      title="Do Fitted Wardrobes Add Value To Your Home | Wow Interior Design"
      description="Explore the benefits of adding new fitted furniture to your home from additional storage space to adding value to your property."
    >
      <Menu />
      <Article
        article={article}
      />
    </Layout>
  );
};

ArticleContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ArticleContainer;
